import React, {useEffect, useState} from 'react';
import styles from './QualifierLeaderboardSection.module.css';
import {TabsProvider} from '../../../components/tabs/TabsProvider';
import {Tab} from '../../../components/tabs/Tab';
import {QualifierLeaderboard} from './leaderboard/QualifierLeaderboard';
import {LeaderboardTabButton} from '../../live_event/leaderboard/leaderboard/LeaderboardTabButton';
import {fetchLeaderboard} from '../../../firebase/firebase';
import {pages as p} from './testleaderboarddata';

type PageData = {
    id: string;
    tabName: string;
    results: any[];
};

export const QualifierLeaderboardSection: React.FC = () => {
    const [pages, setPages] = useState<PageData[]>(p);
    const [error, setError] = useState<string>('');
    const defaultVal = pages[0] || {id: '1', tabName: '1'};
    useEffect(() => {
        // const fetchPages = async () => {
        //     try {
        //         const leaderboard = await fetchLeaderboard();
        //         const results = leaderboard.results;
        //         const pages = [];
        //
        //         for (let i = 0; i < results.length; i += 10) {
        //             const pageResults = results.slice(i, i + 10);
        //             pages.push({
        //                 id: `${i / 10 + 1}`,
        //                 tabName: `${i / 10 + 1}`,
        //                 results: pageResults
        //             });
        //         }
        //
        //         setPages(pages);
        //     } catch (e) {
        //         console.error('Failed to load leaderboard data', e);
        //         setError('Failed to load leaderboard data');
        //     }
        //
        // };
        //
        // fetchPages();
    }, []);

    // Component implementation
    return (
        <div className={styles.container}>
            <div className={`page-wrapper center ${styles.liveLeaderboardSection}`}>
                <h2 className={'center '}>Leaderboard</h2>
                {error && <h3 style={{color: 'red'}}>{error}</h3>}
                <TabsProvider defaultVal={defaultVal.id} defaultMeta={defaultVal.tabName}>
                    <div className={styles.contentContainer}>
                        <div className={styles.sideMapButtons}>
                            {pages.map(page => <LeaderboardTabButton key={page.id}
                                                                     className={styles.tabButton}
                                                                     title={page.tabName}
                                                                     value={page.id}/>)}
                        </div>
                        <div className={styles.leaderboardContainer}>
                            {pages.map(page => <Tab key={page.tabName} value={page.id}>
                                <QualifierLeaderboard
                                    page={page.tabName}
                                    rows={page.results}
                                /></Tab>)}
                        </div>
                    </div>
                </TabsProvider>
                <div className={styles.zaveContainer}>
                    {/*<a href={'https://app.zave.it/#/?creator=@veni'} target={'_blank'} rel={'noreferrer'}>*/}
                    {/*    <img src={ZavImage} className={styles.zaveItLogo} alt={''}/>*/}
                    {/*</a>*/}

                </div>

            </div>
        </div>
    );
};
