import React from 'react';
import {Paper} from '../../../components/paper/Paper';
import {QualifierForm} from './QualifierForm';
import styles from './QualifierFormPage.module.css';
import {useSearchParams} from 'react-router-dom';
import Button from '../../../components/button/Button';
import {Link} from 'react-router-dom';


type QualifierPageProps = {};

export const QualifierFormPage: React.FC<QualifierPageProps> = (props) => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const error = searchParams.get('error');


    if(error) {
        return  <React.Fragment>
            <div className={styles.section}>
                <Paper color={'secondary'}>
                    <div className={styles.formWrapperError}>
                        <h1>Registration Error!</h1>
                        <p>{error}</p>
                        <Link to={'/'}><Button>Go back</Button></Link>
                    </div>
                </Paper>
            </div>

        </React.Fragment>
    }

    if (!token) {
        return  <React.Fragment>
            <div className={styles.section}>
                <Paper color={'secondary'}>
                    <div className={styles.formWrapperError}>
                        <h1>Missing Token!</h1>
                        <p>You need to first verify your discord before you can proceed with the registration! For this go back to peepoSprint and press the Registration button again!</p>
                        <Link to={'/'}><Button>Go back</Button></Link>
                    </div>
                </Paper>
            </div>

        </React.Fragment>
    }
    return (
        <React.Fragment>
            <div className={styles.section}>
                <Paper color={'secondary'}>
                    <div className={styles.formWrapper}>
                        <h2>Qualifier Registration</h2>
                        <p>Wilkommen beim peepoSprint Online Qualifier! Nachdem du nun dein deinen Discord account bestätigt hast, möchte wir dich bitten noch ein paar weitere Angaben zu deiner Person zu machen.</p>
                        <QualifierForm token={token} />
                    </div>
                </Paper>
            </div>

        </React.Fragment>
    );
};
