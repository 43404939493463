export const Qualifier_faq_data = [{
    title: 'Kann jeder mitmachen?',
    description: 'Jede Person aus dem DACH Raum kann beim Qualifier mitmachen! Ihr müsst hierfür nur die Anforderungen, welche ihr im Tab "Anforderungen" findet erfüllen.'
}, {
    title: 'Was sind Tag-Teams',
    description: 'Um den educational Faktor des Turniers zu erhöhen, haben wir uns dazu entschlossen, dass die Teams aus ' +
        'einem Pro und einem Streamer bestehen. So wollen wir Entertainment und Professionalität vereinen.'
}, {
    title: 'Wie kann ich mitmachen?',
    description: 'Dank des Qualifiers geben wir dir nun die Chance selbst mit dabei zu sein! Hierfür musst du dich durch Phase 1 und 2' +
        ' des Qualifiers kämpfen. Die besten 6 Spieler kommen dann als Pros zum Finale.'
}, {
    title: 'Was ist der Qualifier?',
    description: 'Der Qualifier ist ein Turnier, bei dem die besten 6 Spieler aus Phase 1 und 2 sich für das Finale qualifizieren. ' +
        'Hierbei wird in 2 Phasen gespielt, in denen die besten Spieler ermittelt werden.'
}, {
    title: 'Was ist das Finale?',
    description: 'Das Offline Finale im XPERION in Köln ist das Highlight des Turniers. Die besten 6 SpielerInnen aus dem Qualifier kämpfen hier um den Sieg. ' +
        'Das Finale wird in einem Tag-Team Modus gespielt, bei dem die Teams aus einem Pro und einem Streamer bestehen.'
}, {
    title: 'Wie werde ich kontaktiert?',
    description: 'Bei der Anmeldung zum Qualifier musst du neben deinem Discord auch eine E-Mail ' +
        'Adresse angeben. Diese wird von uns genutzt, um dich über den weiteren Verlauf des Turniers zu informieren falls du dich qualifizierst.'
}, {
    title: 'Was ist der Unterschied zwischen einem Pro und einem Streamer?',
    description: 'Ein Pro ist ein professioneller Spieler, der bereits Erfahrung in Turnieren hat. Ein Streamer ist eine Person, die auf ' +
        'Plattformen wie Twitch oder YouTube streamt. In diesem Turnier wollen wir beide Gruppen zusammenbringen.'
}, {
    title: 'Was passiert, wenn ich mich qualifiziere?',
    description: 'Wenn du dich qualifizierst, wirst du von uns über E-Mail kontaktiert. ' +
        'Hierbei werden wir dir alle Informationen zum weiteren Verlauf des Turniers zukommen lassen.'
}
]
