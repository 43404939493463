import * as React from 'react';
import styles from './Navbar.module.css';
import Button from '../button/Button';
import {PeepoLogo} from '../PeepoLogo';
import {NavLink} from 'react-router-dom';
import {
    HOME_ROUTE,
    INFORMATION_ROUTE,
    LAST_EVENT_ROUTE,
    LIVE_EVENT_ROUTE, QUALIFIER_INFO_ROUTE,
    QUALIFIER_LEADERBOARD_ROUTE
} from '../../router/routerUrls';
import {LIVE_LEADERBOARD_SECTION_ID} from '../../pages/live_event/leaderboard/LiveLeaderboardSection';
import {useConfig} from '../ConfigContext';

export function isEventLive(startDate: Date): boolean {
    const currentTime = new Date();
    const targetDateEnd = new Date(startDate.getTime() + 5*60*60*1000);

    return currentTime >= startDate && currentTime <= targetDateEnd;
}

export const Navbar: React.FC = (props) => {

    let peepoSprintConfig = useConfig();
    const live = isEventLive(peepoSprintConfig.startDate?.toDate());

    return (
        <div className={styles.fixedNavbar + ' page-wrapper'}>
            <NavLink to={HOME_ROUTE}><PeepoLogo className={styles.fixedNavbarLogo}/></NavLink>

            <ul className={styles.fixedNavbarItemContainer}>
                {/*<li className={live ? `${styles.fixedNavbarItem} ${styles.live}` : styles.fixedNavbarItem}>*/}
                {/*    <NavLink to={LIVE_EVENT_ROUTE}*/}
                {/*             className={(navData) => navData.isActive ? `${styles.navbarLink} ${styles.active}` : styles.navbarLink}>*/}
                {/*        Live</NavLink>*/}
                {/*</li>*/}
                {/*<li className={styles.fixedNavbarItem}>*/}
                {/*    <NavLink to={INFORMATION_ROUTE}*/}
                {/*             className={(navData) => navData.isActive ? `${styles.navbarLink} ${styles.active}` : styles.navbarLink}>*/}
                {/*        Turnier Information*/}
                {/*    </NavLink>*/}
                {/*</li>*/}
                {/*<li className={styles.fixedNavbarItem}>*/}
                {/*    <NavLink to={LAST_EVENT_ROUTE}*/}
                {/*             className={(navData) => navData.isActive ? `${styles.navbarLink} ${styles.active}` : styles.navbarLink}>*/}
                {/*        Last Events*/}
                {/*    </NavLink>*/}
                {/*</li>*/}
                {/*<li className={styles.fixedNavbarItem}>*/}
                {/*    <NavLink to={LIVE_EVENT_ROUTE + `#${LIVE_LEADERBOARD_SECTION_ID}`} className={styles.navbarLink}>*/}
                {/*        <Button color={'secondary'}>Leaderboard</Button></NavLink>*/}
                {/*</li>*/}
                <li className={styles.fixedNavbarItem}>
                    <NavLink to={QUALIFIER_INFO_ROUTE} className={styles.navbarLink}>
                        Qualifier Infos
                    </NavLink>
                </li>
                <li className={styles.fixedNavbarItem}>
                    <NavLink to={QUALIFIER_LEADERBOARD_ROUTE} className={styles.navbarLink}>
                        <Button color={'secondary'}>Leaderboard</Button></NavLink>
                </li>
            </ul>
        </div>
    );
};
