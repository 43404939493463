import * as React from 'react';
import styles from './IntroSection.module.css';
import {QualifierIntroductionPaper} from './component/QualifierIntroductionPaper';

export const QualifierIntroSection: React.FC = (props) => {
    return (
        <div className={`section ${styles.welcomeContainer}`}>
            <div className={'page-wrapper'}>
                <h1>Qualifier Informationen</h1>
                <p>Zum ersten Mal kannst auch du bei peepoSprint mitmachen! Nimm hierfür am<br/>Qualifier teil und beweise dein können.</p>

                <div className={styles.introductionPaper}>
                    <QualifierIntroductionPaper/>
                </div>

            </div>
        </div>
    );
};
