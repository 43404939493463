import React from 'react';
import './App.css';
import {BrowserRouter as Router} from 'react-router-dom';
import {PeepoRoutes} from './router/Routes';
import {Footer} from './components/footer/Footer';
import {Navbar} from './components/navbar/Navbar';
import ScrollToTop from './components/ScrollToTop';
import {useIsSmallScreen} from './hooks/useIsSmallScreen';
import {SmallNavbar} from './pages/screen/small/SmallNavbar';
import {ScrollToAnchor} from './components/ScrollToAnchor';
import {ConfigProvider} from './components/ConfigContext';

function App() {
    const {isSmallScreen} = useIsSmallScreen();
    return (
        <div className="App">
            <ConfigProvider>
                <Router>
                    <ScrollToAnchor/>
                    {/*{!isSmallScreen && <>*/}
                        <Navbar/>
                        <ScrollToTop/>
                    {/*</>}*/}
                    {/*{isSmallScreen && <SmallNavbar/>}*/}
                    <PeepoRoutes/>
                    <Footer/>
                </Router>
            </ConfigProvider>
        </div>
    );
}

export default App;
