import {EventFormat, Player} from '../types/config';
import {fetchConfig} from '../firebase/firebase';
import {Logger} from './Logger';
import {Timestamp} from 'firebase/firestore';
import {TeamN} from '../types/teams';


export class PeepoSprintConfig {
    private static instance: PeepoSprintConfig;
    public version: string;
    public players: Player[];
    public teams: TeamN[];
    public format: EventFormat;
    public startDate: Timestamp;

    private constructor() {
        // Set default values
        this.version = 'version10';
        this.players = [];
        this.teams = [];
        this.format = 'SINGLE';
        this.startDate = Timestamp.now();
    }

    public static async getInstance(): Promise<PeepoSprintConfig> {
        if (!PeepoSprintConfig.instance) {
            PeepoSprintConfig.instance = new PeepoSprintConfig();
            await PeepoSprintConfig.instance.loadConfigFromFirebase();
        }
        return PeepoSprintConfig.instance;
    }

    public async loadConfigFromFirebase() {
        const config = await fetchConfig();
        if (config) {
            this.version = config.version;
            this.players = config.players;
            this.teams = config.teams;
            this.format = config.format;
            this.startDate = config.startDate || Timestamp.now();
            Logger.debug('Loaded config from Firebase', this);
        } else {
            throw new Error('Could not find a config document in Firebase');
        }
    }


    public getPlayerImage(name: string) {
        return `https://peeposprint.at/assets/member/${name.toLowerCase()}.png`;
    }

    public getTeam(ingName: string) {
        let find = this.teams.find(team => team.members.includes(ingName));
        if(!find) {
            return {
                id: 0,
                name: 'Unknown',
                color: '#808080',
                members: [ingName, 'Unknown']
            }
        }
        return find;
    }

    public getTeamById(id: number) {
        let find = this.teams.find(team => team.id === id);
        if(!find) {
            return {
                id: 0,
                name: 'Unknown',
                color: '#808080',
                members: ['Unknown', 'Unknown']
            }
        }
        return find;
    }


    public getPlayer(ingName: string) {
        let find = this.players.find(team => team.ingName === ingName);
        if(!find) {
            return {
                name: 'Unknown',
                ingName: 'Unknown',
                socials: ['Unknown', 'Unknown'],
                team: 0,
                category: 'Unknown'
            }
        }
        return find;
    }


}
