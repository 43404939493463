import * as React from 'react';
import styles from './Partner.module.css';
import {PartnerLogo} from './PartnerLogo';
import RBLogo from '../../assets/website/partner/rb_logo.png';
import ZaveIt from '../../assets/website/partner/zaveit.svg';
import {useIsSmallScreen} from '../../hooks/useIsSmallScreen';

interface PartnerContainerProps {
    padding?: boolean;
}

export const PartnerContainer: React.FC<PartnerContainerProps> = ({padding = true}) => {

    const distance = padding ? 200 : 0;
    const {isSmallScreen} = useIsSmallScreen();

    return (
        <div className={styles.partnerContainer} style={{['--distance' as any]: `${distance}px`}}>
            {/*{isSmallScreen && <>*/}
            {/*    <PartnerLogo image={RBLogo}*/}
            {/*    />*/}
            {/*    <PartnerLogo image={ZaveIt} url={'https://app.zave.it/#/?creator=@veni'} small={true}*/}
            {/*    />*/}
            {/*    <PartnerLogo image={ZaveIt} url={'https://app.zave.it/#/?creator=@veni'} small={true}*/}
            {/*    />*/}
            {/*    <PartnerLogo image={RBLogo}*/}
            {/*    />*/}

            {/*</>}*/}
            {/*{!isSmallScreen && <>*/}
            {/*    <PartnerLogo image={RBLogo}*/}
            {/*    />*/}
            {/*    <PartnerLogo image={ZaveIt} url={'https://app.zave.it/#/?creator=@veni'}*/}
            {/*    />*/}
            {/*    <PartnerLogo image={RBLogo}*/}
            {/*    />*/}
            {/*    <PartnerLogo image={ZaveIt} url={'https://app.zave.it/#/?creator=@veni'}*/}
            {/*    />*/}
            {/*    <PartnerLogo image={RBLogo}/>*/}
            {/*</>}*/}
        </div>
    );
};
