import * as React from 'react';
import styles from './Leaderboard.module.css';
import {QualifierPlayerLeaderboardRow} from './QualifierPlayerLeaderboardRow';


export interface LiveLeaderboardProps {
    page: string;
    rows: any;
}


export const QualifierLeaderboard: React.FC<LiveLeaderboardProps> = ({page, rows}) => {

    return (
        <div className={styles.leaderboardContainer}>
            <div className={styles.topControls}>
                <span className={styles.seedText}>{`Page ${page}`}</span>
            </div>
            <div className={styles.leaderboardContainer}>

                {rows &&
                    // @ts-ignore
                   rows?.map((row) => <QualifierPlayerLeaderboardRow row={row} key={row.playerName} />)}
                {rows && (!rows || !rows?.length) && <p>No data available</p>}
            </div>
        </div>
    );
};
