import React from 'react';
import {Paper} from '../../../components/paper/Paper';
import styles from './QualifierPage.module.css';
import Button from '../../../components/button/Button';
import {Link} from 'react-router-dom';
import {getDiscordOauthUrl} from '../../../utils/qualifier_service';
import {QualifierCountdown} from './QualifierCountdown';
import Trophy from '../../../assets/website/qualifier/Trophy.svg';
import Leaderboard from '../../../assets/website/qualifier/Leaderboard.svg';
import Register from '../../../assets/website/qualifier/Register.svg';
import {QUALIFIER_INFO_ROUTE} from '../../../router/routerUrls';

type QualifierPageProps = {};

export const QualifierPage: React.FC<QualifierPageProps> = (props) => {
    const END_DATE = new Date('2024-06-20T00:00:00+02:00');
    //peepoSprintConfig.startDate?.toDate();

    return (
        <div className={styles.countdownSection}>
            <div className={styles.layer2}/>
            <div className={styles.countdownWrapper}>
                <QualifierCountdown targetDate={END_DATE}/>

                <Paper classname={styles.qualifierPaper} color={'secondary'}>
                    <h4>Der peepoSprint Qualifier ist in 2 Phase aufgeteilt</h4>
                    <div className={styles.gridContainer}>
                        <div className={styles.gridItem}>
                            <img src={Register} alt={''} className={styles.gridIcon}/>
                            <p className={styles.gridText}>Zuerst registriere dich<br/>mit Discord</p>
                        </div>
                        <div className={styles.gridItem}>
                            <img src={Leaderboard} alt={''} className={styles.gridIcon}/>
                            <p className={styles.gridText}>Bestehe Phase 1:</p>
                            <p className={styles.gridText}>MCSR Leaderboard</p>
                        </div>
                        <div className={styles.gridItem}>
                            <img src={Trophy} alt={''} className={styles.gridIcon}/>
                            <p className={styles.gridText}>Dominiere Phase 2:</p>
                            <p className={styles.gridText}>Turnier</p>
                        </div>
                    </div>
                    <p style={{marginTop: 20, color: 'gray'}}>Details zum Turnier können <Link to={QUALIFIER_INFO_ROUTE} style={{
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                        color: 'gray'
                    }}>hier</Link> eingesehen werden.</p>
                    <ul className={styles.explainList}>
                        <li><b>1. Registrierung deines MCSR Accounts</b><br/>Verbinde deinen Discord Account mit deinem
                            MCSR Account im Minecraft Client.<br/>(Falls du das noch nicht gemacht hast)
                        </li>
                        <li><b>2. Benutzung des Discord Accounts</b><br/>Sobald die Verbindung hergestellt ist, kannst
                            du deinen Discord Account verwenden, um dich bei peepoSprint zu registrieren.
                        </li>
                        <li><b>3. Autorisiere dein Discord Profil</b><br/>Erlaube uns, auf dein Discord Profil
                            zuzugreifen.
                        </li>
                        <li><b>4. E-Mail Adresse angeben</b><br/>Nach der Autorisierung wirst du zu einem Formular
                            weitergeleitet, wo du deine E-Mail Adresse eintragen kannst. Diese wird benötigt, um dich in
                            Phase 2 des Turniers zu kontaktieren.
                        </li>
                        <li><b>5. MCSR Elo farmen</b><br/>Deine EloRate am 21.06 um 00:00 (Vienna Time) ist
                            ausschlaggebend für das Turnier. Farm also bis dahin so viel Elo wie möglich.
                        </li>
                    </ul>
                    <Link to={getDiscordOauthUrl()} style={{paddingLeft: 30}}>
                        <Button>Register for Qualifier</Button>
                    </Link>
                    <p style={{marginTop: 10, color: 'gray'}}>(Bevor du dich registrierst ließ dir bitte die
                        Details <Link to={QUALIFIER_INFO_ROUTE} style={{
                            fontWeight: 'bold',
                        textDecoration: 'underline',
                        color: 'gray'
                    }}>hier</Link>  nochmals
                        durch)</p>
                </Paper>

            </div>


        </div>
    );
};
