import * as React from 'react';
import styles from './RulesSlide.module.css';

interface FaqSlideProps {
    data: {
        title: string;
        description: string | string[]
    }
}

export const QualifierRulesSlide: React.FC<FaqSlideProps> = ({data}) => {
    return (
        <div className={styles.rulesSlide}>
            <div className={styles.contentSlide}>
                <h3>{data.title}</h3>
                {data?.description && Array.isArray(data.description) ? data.description.map((desc, index) => <p key={index}>{desc}</p>) : null}
                {data?.description && !Array.isArray(data.description) && <p>{data.description}</p>}
            </div>
        </div>
    );
};
