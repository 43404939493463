interface Slide {
    title: string;
    description: string | string[];
}

interface RuleType {
    title: string;
    value: string;
    slides: Slide[];
}


export const RULE_TYPES: RuleType[] = [{
    title: 'General',
    value: 'general',
    slides: [{
        title: 'Philosophie',
        description: 'Das Turnier steht im Zeichen des Entertainmentfaktors. Es wurde sorgfältig balanciert und organisiert, um Spaß für TeilnehmerInnen und ZuschauerInnen zu gewährleisten. Fairness und Sportsgeist sind unsere höchsten Prinzipien. Respektloses Verhalten, Regelverstöße zur Vorteilserlangung oder Missachtung der Organisation können zu Konsequenzen wie Punktabzug, Ausschluss oder Verlust des Preisgeldes führen.'
    }, {
        title: 'MCSR?',
        description: 'MCSR ist ein Ranked Minecraft Speedrun Client, der die Möglichkeit bietet, kompetitiv auf Elo-Basis zu spielen. Dabei wirst du basierend auf deiner Elo mit Gegnern deines Levels gematched und erhältst oder verlierst entsprechend Punkte bei einem Sieg oder einer Niederlage. Für peepoSprint werden wir die Elo-Bewertung von MCSR für Phase 1 verwenden.'
    }, {
        title: 'Account',
        description: 'Um euch einen Account zu erstellen, müsst ihr den MCSR Client herunterladen und starten. Anschließend könnt ihr dort euren Discord-Account verlinken. Sobald dies erledigt ist, könnt ihr euch auf der peepoSprint-Website mit eurem Discord registrieren und erscheint beim nächsten Leaderboard-Update auf der Rangliste.'
    }, {
        title: 'Preisgeld (Finale)',
        description: [
            '- Gewinnerteam: jeweils 1.000€ pro Spieler',
            '- Schnellster individueller Run(MVP): 500€',
            'Summe: 2.500€'
        ]
    }]
}, {
    title: 'Anforderungen',
    value: 'requirements',
    slides: [{
        title: 'DACH Raum',
        description: 'Um am Qualifier teilzunehmen, musst du im DACH(Deutschland, Österreich oder der Schweiz) wohnen.'
    }, {
        title: 'Livestream',
        description: 'Das Turnier findet nach der Leaderboard-Phase statt. Hierfür müsst ihr in der Lage sein, während des gesamten Turniers auf Twitch oder YouTube zu streamen.'
    }, {
        title: 'Zeitfenster',
        description: 'Die TeilnehmerInnen müssen am Wochenende des Finales vom 12.07-14.06.2024 verfügbar sein. Die genauen Zeiten werden noch bekannt gegeben. Falls ihr nicht verfügbar seid, wird euer Platz an den nächsten Spieler auf der Warteliste vergeben.'
    }]
}, {
    title: 'Qualifier',
    value: 'qualifier',
    slides: [{
        title: 'Timing',
        description: [`- Qualifier Start: 29.05.2024`,
            '- Qualifikationsphase: bis 20.06.2024',
            '- Online Qualifikation: 23.06.2024',
            '- Offline Anreise/Probe/Content: 12.07.2024',
            '- Offline Event: 13.07.2024'
        ]
    }, {
        title: 'Verfügbarkeit',
        description: 'Alle Teilnehmer:Innen müssen zum Zeitpunkt des Online Qualifikationsturnier bereit, verfügbar und fähig sein am 12.7. - 14.7. in Köln für das Offline Finale anzureisen und erklären sich bereit für etwaige Aktionen vor der Kamera, welche für die Streamproduktion und Gestaltung der Liveshow relevant sind. Anreise bei unter 7h Dauer per Zug.'
    }, {
        title: 'Leaderboard',
        description: 'Hierbei werden die Top 2 sofort zum Offline Finale eingeladen, die Plätze 3-16 werden zu einem Online Turnier eingeladen, um die restlichen Plätze zu erspielen.'
    }, {
        title: 'Turnier',
        description: 'Alle zum Turnier eingeladenen Spieler müssen am Tag des Turniers in der Lage sein zu streamen. Über den MCSR Ranked Client erstellen wir eine private Lobby und somit spielen alle 14 teilnehmenden Personen den selben Seed zur selben Zeit.'
    }, {
        title: 'Format',
        description: ['Folgendes Format verwenden wir für das Turnier:',
            '- Seed 1: Keine Eliminierungen',
            '- Seed 2: Keine Eliminierungen',
            '- Seed 3: Keine Eliminierungen',
            '- Seed 4: Untere 50% der Punkte scheiden aus',
            '- Seed 5: Keine Eliminierungen',
            '- Seed 6: Top 6 kommt weiter',
            '- Seed 7: Top 4 kommt weiter',
            'Damit ergibt sich dann das Seeding nach Platzierung und die Teams für das Offline Event.'
        ]
    }, ]
}];
