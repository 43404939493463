import React from 'react';
import styles from './QualifierPlayerLeaderboardRow.module.css';
import {QualifierLeaderboardEntry} from '../../../../types/qualifier';
import {useIsSmallScreen} from '../../../../hooks/useIsSmallScreen';

interface PlayerLeaderboardRowProps {
   row: QualifierLeaderboardEntry;
}

export const QualifierPlayerLeaderboardRow: React.FC<PlayerLeaderboardRowProps> = ({row}) => {
    const {rank, playerName, eloRank, eloRate} = row;
    let {isSmallScreen} = useIsSmallScreen();
    const color = rank % 2 === 0 ? 'transparent' : 'var(--secondary-dark)';
    return (
        <div className={styles.qualifierLeaderboardRow} style={{['--row-color' as any]: color}}>
            <span className={styles.rank}>{rank}</span>
            <span className={styles.name}>{playerName}</span>
            <span className={styles.eloRank}>{`${!isSmallScreen ? 'Rank' : '#'} ${eloRank}`}</span>
            <span className={styles.placeholder}></span>
            <span className={styles.eloRate}>{eloRate} Elo</span>
        </div>
    );
};
