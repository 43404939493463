import * as React from 'react';
import {useEffect, useState} from 'react';
import styles from './Countdown.module.css';
import {getRemainingTime} from '../../../utils/timing';
import {TimePart} from '../../home/sections/countdown/component/TimePart';

type CountdownProps = {
    targetDate: Date;
}

export const QualifierCountdown: React.FC<CountdownProps> = ({targetDate}) => {
    const [remainingTime, setRemainingTime] = useState(getRemainingTime(targetDate));

    useEffect(() => {
        const interval = setInterval(() => {
            const currentTime = new Date().getTime();
            const difference = targetDate.getTime() - currentTime;

            if (difference <= 0) {
                clearInterval(interval);
                return;
            }

            setRemainingTime(getRemainingTime(targetDate));
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [targetDate]);


    return (
        <div className={styles.countdownContainer}>
            <h1 style={{textAlign: 'center'}}>Qualifier will be Open Soon</h1>
            {/*<span className={styles.spacedText}>Ends in</span>*/}
            {/*<div className={styles.timeContainer}>*/}
            {/*    <TimePart time={remainingTime.days} label={'days'}/>*/}
            {/*    <span className={styles.separator}>:</span>*/}
            {/*    <TimePart time={remainingTime.hours} label={'hours'}/>*/}
            {/*    <span className={`${styles.separator}`}>:</span>*/}

            {/*    <TimePart time={remainingTime.minutes} label={'minutes'}/>*/}
            {/*    <span className={styles.separator}>:</span>*/}
            {/*    <TimePart time={remainingTime.seconds} label={'seconds'}/>*/}
            {/*</div>*/}


        </div>
    );
};
