import * as React from 'react';
import styles from './FaqSection.module.css';
import {Qualifier_faq_data} from './qualifier_faq_data';
import Accordion from '../../../../components/accordion/Accordion';
import {PartnerContainer} from '../../../../components/partners/PartnerContainer';




export const QualifierFaqSection: React.FC = () => {
    return (
        <div className={styles.faqSection}>
            <div className={'page-wrapper center'}>
                <h2>FAQ</h2>
                {Qualifier_faq_data && Qualifier_faq_data.map((faq, index) => (
                    <Accordion key={faq.title} title={faq.title} className={styles.accordion}>
                        <p className={styles.faqText}>{faq.description}</p>
                    </Accordion>
                ))}
                <PartnerContainer/>
            </div>
        </div>
    );
};
