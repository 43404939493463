export const pages = Array.from({ length: 7 }, (_, pageIndex) => ({
    id: `${pageIndex + 1}`,
    tabName: `${pageIndex + 1}`,
    results: Array.from({ length: 10 }, (_, rankIndex) => ({
        rank: rankIndex + 1,
        playerName: `Player${pageIndex * 10 + rankIndex + 1}`,
        eloRate: 100 - rankIndex * 10,
        eloRank: rankIndex + 1,
    }))
}));
