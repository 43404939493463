import * as React from 'react';
import {QualifierRulesTabs} from './components/QualifierRulesTabs';
import {RULE_TYPES} from '../qualifier_rule_data';
import styles from './RulesSection.module.css';
import {TabsProvider} from '../../../../components/tabs/TabsProvider';

export const QualifierRulesSection: React.FC = (props) => {
    const defaultVal =  RULE_TYPES.length ? RULE_TYPES[0].value : undefined;

    return (
        <>
            <div className={`section ${styles.rulesSection}`}>
                <div className={'page-wrapper center'}>
                    <h2>Infos</h2>
                    <p className={styles.rulesSubtitle}>Hier findest du nochmal alle Informationen im Detail.</p>
                   <div className={styles.rulesContent}>
                       <TabsProvider defaultVal={defaultVal}>
                           <QualifierRulesTabs />
                       </TabsProvider>
                   </div>
                </div>
            </div>
        </>

    );
};
