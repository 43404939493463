import * as React from 'react';
import {Route, Routes} from 'react-router-dom';
import {
    IMPRINT_ROUTE,
    PRIVACY_ROUTE, QUALIFIER_INFO_ROUTE, QUALIFIER_LEADERBOARD_ROUTE, QUALIFIER_HOME_PATH
} from './routerUrls';
import {Imprint} from '../pages/utility/Imprint';
import {Privacy} from '../pages/utility/Privacy';
import {useIsSmallScreen} from '../hooks/useIsSmallScreen';
import {QualifierPage} from '../pages/qualifier/home/QualifierPage';
import {QualifierFormPage} from '../pages/qualifier/register/QualifierFormPage';
import {QualifierInfoPage} from '../pages/qualifier/info/QualifierInfoPage';
import {QualifierLeaderboardSection} from '../pages/qualifier/leaderboard/QualifierLeaderboardSection';


export const PeepoRoutes: React.FC = (props) => {
    const {isSmallScreen} = useIsSmallScreen();
    return (
        <Routes>
            {/*{isSmallScreen && <>*/}
            {/*    <Route path={HOME_ROUTE} element={<SmallScreenPage/>}/>*/}
            {/*</>}*/}
            <Route path={IMPRINT_ROUTE} element={<Imprint/>}/>
            <Route path={PRIVACY_ROUTE} element={<Privacy/>}/>
            {/*{!isSmallScreen && <>*/}
            {/*    <Route path={LIVE_EVENT_ROUTE} element={<LiveEventPage/>}/>*/}
            {/*    <Route path={INFORMATION_ROUTE} element={<QualifierInfoPage/>}/>*/}
            {/*    <Route path={LAST_EVENT_ROUTE} element={<LastEventsPage/>}/>*/}
            {/*    <Route path={HOME_ROUTE} element={<HomePage/>}/>*/}
            {/*</>}*/}
            <Route path={QUALIFIER_LEADERBOARD_ROUTE} element={<QualifierLeaderboardSection/>}/>
            <Route path={QUALIFIER_INFO_ROUTE} element={<QualifierInfoPage/>}/>
            <Route path={QUALIFIER_HOME_PATH} element={<QualifierFormPage/>}/>
            <Route path={''} element={<QualifierPage/>}/>
            {/*<Route path={PREVIEW_ROUTE} element={<PreviewPage/>}/>*/}
        </Routes>
    );
};
