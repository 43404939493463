import React from 'react';
import styles from './IntroductionPaper.module.css';
import {NavLink} from 'react-router-dom';
import {MonsterPaper} from '../../../../../components/paper/MonsterPaper';
import {HOME_ROUTE, QUALIFIER_LEADERBOARD_ROUTE} from '../../../../../router/routerUrls';
import Button from '../../../../../components/button/Button';

interface IntroductionPaperProps {
    // Props definition
}

export const QualifierIntroductionPaper: React.FC<IntroductionPaperProps> = () => {
    // Component implementation
    return (
        <MonsterPaper>
            <h2>Qualifier</h2>
            <p className={styles.textBlock}>Das peepoSprint Speedrun Invitational ist das erste Turnier seiner Art
                im deutschsprachigen Raum! Nun gibt es auch eine Offline Ausgabe!</p>
            <p className={styles.textBlock}>Hierfür haben wir Streamer invited und lassen euch in einem online Qualifier für die Pro Slots antreten!</p>
            <div className={styles.buttonContainer}>
                <NavLink to={QUALIFIER_LEADERBOARD_ROUTE}>
                    <Button>Leaderboard</Button>
                </NavLink>
                <NavLink to={HOME_ROUTE}>
                    <Button color={'secondary'}>Registrieren</Button>
                </NavLink>
            </div>
        </MonsterPaper>
    );
};
