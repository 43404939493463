import * as React from 'react';
import {QualifierIntroSection} from './intro/QualifierIntroSection';
import {QualifierRulesSection} from './rules/QualifierRulesSection';
import {QualifierFaqSection} from './faq/QualifierFaqSection';
import {BlockTransition} from '../../../components/transition/BlockTransition';
import {NavbarPlaceholder} from '../../../components/navbar/NavbarPlaceholder';

export const QualifierInfoPage: React.FC = (props) => {
    return (
        <React.Fragment>
            <BlockTransition align={'bottom'} orientation={'up'}>
                <NavbarPlaceholder/>
                <QualifierIntroSection/>
            </BlockTransition>

            <QualifierRulesSection/>
            <BlockTransition align={'top'} orientation={'down'}>
                <QualifierFaqSection/>
            </BlockTransition>

        </React.Fragment>
    );
};
