// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';
import {doc, getDoc, getFirestore, connectFirestoreEmulator} from 'firebase/firestore';
import {getDatabase, onValue, ref, connectDatabaseEmulator} from 'firebase/database';
import {LeaderboardType} from '../utils/leaderboard_const';
import {MapPlayerLeaderboard, MapTeamLeaderboard, PlayerLeaderboard, TeamLeaderboard} from '../types/leaderboard';
import { getFunctions, httpsCallable, connectFunctionsEmulator} from "firebase/functions";
import {PeepoSprintConfigData} from '../types/config';
import {Logger} from '../utils/Logger';
import {PeepoSprintConfig} from '../utils/PeepoSprintConfig';
import {QualifierLeaderboard} from '../types/qualifier';


const firebaseConfig = {
    apiKey: 'AIzaSyALeFlpBdq3EyCyvSdFUOahJI2Zlk5g1yo',
    authDomain: 'odyssey-442a0.firebaseapp.com',
    databaseURL: "https://odyssey-442a0-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: 'odyssey-442a0',
    storageBucket: 'odyssey-442a0.appspot.com',
    messagingSenderId: '118761131539',
    appId: '1:118761131539:web:919bb07498b4b0e17439e6',
    measurementId: 'G-JQJR4X987S'
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const realTimeDB = getDatabase(app);
const functions = getFunctions(app, 'europe-west3');

if (isLocalhost()) {
    console.log('Using local firestore emulator');
    connectFirestoreEmulator(db, 'localhost', 8080);
    connectDatabaseEmulator(realTimeDB, 'localhost', 9000);
    connectFunctionsEmulator(functions, 'localhost', 5001);
}

function isLocalhost() {
    const hostname = window.location.hostname;
    return (hostname === 'localhost' || hostname === '127.0.0.1');
}


export function getMapLeaderboard(mapId: string | number, type: LeaderboardType, callback: (data: MapPlayerLeaderboard | MapTeamLeaderboard) => void) {
    const prefix = type === LeaderboardType.TEAM ? 'team_' : '';
    let id = `${prefix}${mapId}`;

    const dbRef = ref(realTimeDB, `/leaderboard/${id}`);
    return onValue(dbRef, (snapshot) => {
        const data = snapshot.val();
        callback(data);
    });
}

export function getGlobalLeaderboard(type: LeaderboardType, callback: (data: PlayerLeaderboard | TeamLeaderboard) => void) {
    const prefix = type === LeaderboardType.TEAM ? 'team' : 'player';
    let id = `${prefix}_global`;

    const dbRef = ref(realTimeDB, `/leaderboard/${id}`);
    return onValue(dbRef, (snapshot) => {
        const data = snapshot.val();
        callback(data);
    });

}


export const fetchConfig = async (): Promise<PeepoSprintConfigData> => {

    // Load the configuration from Firebase
    const docRef = doc(getFirestore(), 'peepoSprint', 'config');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        const data = docSnap.data();

        const expectedFields = ['version', 'players', 'teams', 'format'];
        const allFieldsExist = expectedFields.every(field => field in data);

        if (!allFieldsExist) {
            Logger.warn('One or more fields are missing from the config data');
        }
        Logger.debug('Loaded config from Firebase', data);
        return data as unknown as PeepoSprintConfigData;
    } else {
        throw new Error('Could not find a config document in Firebase');
    }
}

export const fetchLeaderboard = async (): Promise<QualifierLeaderboard> => {
    let config = await PeepoSprintConfig.getInstance();
    // Load the configuration from Firebase
    const docRef = doc(getFirestore(), 'peepoSprint', config.version, 'leaderboards', 'qualifier');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        const data = docSnap.data();
        return data as unknown as QualifierLeaderboard;
    } else {
        throw new Error(`Could not find a leaderboard document in firestore at ${docRef.path}`);
    }
}


export const registerPlayer = async (token: string, email: string) => {
    return httpsCallable(functions, 'qualifier-register')({token, email})
}



