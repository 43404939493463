import React from 'react';
import styles from './QualifierForm.module.css';
import Button from '../../../components/button/Button';
import {TextInput} from '../../../components/form/TextInput';
import {registerPlayer} from '../../../firebase/firebase';
import {useNavigate} from 'react-router-dom';

type QualifierFormProps = {
    token: string
};

export const QualifierForm: React.FC<QualifierFormProps> = ({token}) => {
    const [load, setLoading] = React.useState(false);
    const [error, setError] = React.useState<String>();
    const [success, setSuccess] = React.useState(false);
    const navigate = useNavigate();

    const form = React.useRef(null);


    const onSubmit = async (e: React.FormEvent<HTMLElement>) => {
        e.preventDefault();
        if (load) return;

        // @ts-ignore
        let formData = new FormData(e.target);
        setLoading(true);

        const email = formData.get('email')?.toString();
        if (!email) return setError('Please enter your email address!');

        try {
            const result = await registerPlayer(token, email);
            console.log('result', result)
            setError(undefined);
            setSuccess(true);
            setTimeout(() => {
                navigate('/');
            }, 10000);
        } catch (error: any) {
            console.error(error);
            if (error.message)
                setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    if(success) {
        return <h4 className={styles.successMessage}>Du hast dich erfolgreich registriert! Du wirst in wenigen Sekunden auf die Homepage weitergeleitet!</h4>
    }

    return (
        <form onSubmit={onSubmit} ref={form} className={styles.form}>
            <TextInput name={'email'} type={'email'} placeholder={'your email*'}
                       required={true}/>

            <label className={styles.checkboxLabel}>
                <input type="checkbox" name="consent" required/>
                <span className={styles.checkboxText}>
                            Ich stimme zu, dass der Veranstalter meine angegebenen Daten zum Zweck der Teilnahme am Qualifier speichert.
                        </span>
            </label>
            <div className={styles.submitButtons}>
                <Button type={'reset'} color={'secondary'} disabled={load || success}>Reset</Button>
                <Button type={'submit'} disabled={load || success}>Submit</Button>
            </div>

            <div style={{position: 'relative'}}>
                {success &&
                    <p className={styles.successMessage}>Du hast dich erfolgreich registriert! Du wirst in wenigen
                        Sekunden auf die Homepage weitergeleitet!</p>}
                {error && <p className={styles.errorMessage}>Error: {error}</p>}
            </div>
        </form>
    );
};
